// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_navbar__7zNRs {\n  width: 100%;\n  height: 80px;\n  display: flex;\n  box-shadow: 0px 4px 73px -14px rgba(0, 0, 0, 0.15);\n}\n.style_navbar__7zNRs.style_navbarMobile__3IBD9 {\n  justify-content: space-between;\n}\n.style_navbar__7zNRs .style_drawerBtn__1l5HH {\n  background-color: transparent;\n  border: none;\n  color: #00c2ff;\n  margin-right: 1em;\n}\n.style_navbar__7zNRs .style_navbarLogo__I5lrH {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n.style_navbar__7zNRs .style_logo__34--E {\n  width: -moz-fit-content;\n  width: fit-content;\n  max-height: 6em;\n  cursor: pointer;\n}\n\n.style_drawer__MoAu5 {\n  display: flex;\n  flex-direction: column;\n}", "",{"version":3,"sources":["webpack://src/components/Navbar/style.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,kDAAA;AACF;AACE;EACE,8BAAA;AACJ;AAEE;EACE,6BAAA;EACA,YAAA;EACA,cAAA;EACA,iBAAA;AAAJ;AAGE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AADJ;AAIE;EACE,uBAAA;EAAA,kBAAA;EACA,eAAA;EACA,eAAA;AAFJ;;AAMA;EACE,aAAA;EACA,sBAAA;AAHF","sourcesContent":[".navbar {\n  width: 100%;\n  height: 80px;\n  display: flex;\n  box-shadow: 0px 4px 73px -14px rgba(0, 0, 0, 0.15);\n\n  &.navbarMobile {\n    justify-content: space-between;\n  }\n\n  .drawerBtn {\n    background-color: transparent;\n    border: none;\n    color: #00c2ff;\n    margin-right: 1em;\n  }\n\n  .navbarLogo {\n    height: 100%;\n    display: flex;\n    align-items: center;\n  }\n\n  .logo {\n    width: fit-content;\n    max-height: 6em;\n    cursor: pointer;\n  }\n}\n\n.drawer {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": "style_navbar__7zNRs",
	"navbarMobile": "style_navbarMobile__3IBD9",
	"drawerBtn": "style_drawerBtn__1l5HH",
	"navbarLogo": "style_navbarLogo__I5lrH",
	"logo": "style_logo__34--E",
	"drawer": "style_drawer__MoAu5"
};
export default ___CSS_LOADER_EXPORT___;
