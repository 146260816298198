import { useState, useRef, useEffect } from "react";

import { BalloonWrapper } from "../BalloonWrapper";

import style from "./style.module.scss";

export function Autocomplete({
  suggestions,
  inputValue,
  onChange,
  updater,
  placeholder,
  onClick,
  ...rest
}) {
  const componentRef = useRef(null);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const limit = 20;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  async function removeDuplicatas(arrayA, arrayB) {
    const conjuntoB = new Set(arrayB);
    return arrayA.filter(async (elemento) => {
      if (!conjuntoB.has(elemento)) {
        return true;
      }
      return false;
    });
  }

  const checkValue = async (a, b) => {
    return (
      a
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .startsWith(
          b
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\s/g, "")
            .toLowerCase()
        ) > -1
    );
  };

  let lastFiltered = {};

  const handleChange = async (e) => {
    onChange(e.target.value);

    const listOfSuggestions = updater
      ? await updater(e.target.value)
      : suggestions;

    let search = e.target.value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s/g, "")
      .toLowerCase();

    // console.log("Pesquisa", e.target.value, search, listOfSuggestions.length);

    let filtered = [];
    Promise.all(
      listOfSuggestions.map(async (wordSuggestion, index) => {
        if (filtered.length >= limit) return;
        if (
          wordSuggestion
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\s/g, "")
            .toLowerCase()
            .startsWith(search)
        )
          filtered.push(wordSuggestion);
      })
    );

    // console.log("Filtrado", filtered);

    setFilteredSuggestions(
      filtered.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      })
    );
    setShowSuggestions(true);
    setActiveSuggestionIndex(0);
  };

  const onKeyDown = (e) => {
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);

    // Se a tecla pressionada for Enter
    if (e.keyCode === 13) {
      setActiveSuggestionIndex(0);
      setShowSuggestions(!showSuggestions);
    }
  };

  return (
    <div className={style.autocomplete} ref={componentRef}>
      <input
        type="text"
        onChange={handleChange}
        onKeyDown={onKeyDown}
        value={inputValue}
        onClick={async () => {
          setFilteredSuggestions(
            filteredSuggestions.length
              ? filteredSuggestions
              : suggestions.slice(0, limit)
          );
          setShowSuggestions(true);
        }}
        placeholder={placeholder || "Pesquisar"}
      />
      <BalloonWrapper open={showSuggestions}>
        {
          <ul
            style={{
              display: filteredSuggestions.length > 0 ? "flex" : "none",
              gap: "10px",
              maxHeight: "200px",
              width: "300px",
              overflow: "auto",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              // width: "100%",
            }}
          >
            {filteredSuggestions.map((suggestion, i) => {
              return (
                <li
                  onClick={async (e) => {
                    if (onClick)
                      try {
                        await onClick({ suggestion, i });
                      } catch (error) {
                        console.log("Click error", error);
                      }
                    setShowSuggestions(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                    padding: "8px",
                    margin: "1px",
                    color: "#000000",
                    fontSize: "14px",
                    cursor: "pointer",
                    fontFamily: "Roboto",
                  }}
                >
                  {suggestion.toString()}
                </li>
              );
            })}
          </ul>
        }
      </BalloonWrapper>
    </div>
  );
}
