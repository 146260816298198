import { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useHttp } from "../../hooks/useHttp";
import { useHistory } from "react-router-dom";
import { useSearch } from "../../hooks/useSearch";

import { Autocomplete } from "../Autocomplete";
import { BalloonWrapper } from "../BalloonWrapper";
import { CalendarComponent } from "../CalendarComponent";
import { EventSuggestion } from "../Suggestion/EventSuggestion";
import { LocationSuggestion } from "../Suggestion/LocationSuggestion";
import { ToggleButtonComponent } from "../ToggleButton";

import style from "./style.module.scss";
import { FaSearch } from "react-icons/fa";

export function SearchWithFiltersBar() {
  const { search, setSearch } = useSearch();
  const { request } = useHttp();
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 930 });

  const sectionThreeWrapperRef = useRef(null);
  const sectionThreeButtonRef = useRef(null);
  const sectionFourWrapperRef = useRef(null);
  const sectionFourButtonRef = useRef(null);

  const [sectionThreeOpen, setSectionThreeOpen] = useState(false);
  const [sectionFourOpen, setSectionFourOpen] = useState(false);

  const [toggleButtonValue, setToggleButtonValue] = useState("Calendário");
  const [eventsList, setEventsList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);

  const withDebounceFunction = (func, delay) => {
    let debounceTimer;

    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // make a reusable function for handle click outside
  const handleClickOutside = (event, doNothing, ref, func) => {
    if (doNothing) return;

    if (ref.current && !ref.current.contains(event.target)) {
      func(false);
    }
  };

  useEffect(() => {
    const argsForSectionThree = [
      "mousedown",
      (event) =>
        handleClickOutside(
          event,
          sectionThreeOpen,
          sectionThreeWrapperRef,
          setSectionThreeOpen
        ),
    ];
    const argsForSectionFour = [
      "mousedown",
      (event) =>
        handleClickOutside(
          event,
          sectionFourOpen,
          sectionFourWrapperRef,
          setSectionFourOpen
        ),
    ];

    document.addEventListener(...argsForSectionThree);
    document.addEventListener(...argsForSectionFour);

    return () => {
      document.removeEventListener(...argsForSectionThree);
      document.removeEventListener(...argsForSectionFour);
    };
  }, []);

  useEffect(() => {
    (async () => {
      request("/buffet_event_type", false, "GET").then(async ({ data }) => {
        setEventsList(data.data.map((event) => event.name));
      });
      request("/city_name", false, "GET").then(async ({ data }) => {
        setLocationsList(
          data.data.map((event) => event.name + " - " + event.state.initials)
        );
      });
      //  { data } = await request("/city_name?search=" + value, false, "GET");
    })();
  }, []);

  return (
    <div
      className={`${
        isMobile ? style.searchSection : style.searchWithFiltersBar
      }`}
    >
      {/* evento */}
      <div className={style.searchWithFiltersBar__section}>
        <div className={style.searchWithFiltersBar__section__title}>Evento</div>
        <div className={style.searchWithFiltersBar__section__content}>
          <Autocomplete
            placeholder="O que pretende fazer?"
            inputValue={search.eventType}
            suggestions={eventsList}
            onClick={async ({ suggestion }) =>
              setSearch({ ...search, eventType: suggestion })
            }
            onChange={async (value) =>
              setSearch({ ...search, eventType: value })
            }
          />
        </div>
      </div>

      {/* local */}
      <div className={style.searchWithFiltersBar__section}>
        <div className={style.searchWithFiltersBar__section__title}>Local</div>
        <div className={style.searchWithFiltersBar__section__content}>
          <Autocomplete
            placeholder="Aonde quer festejar?"
            inputValue={search.location}
            suggestions={locationsList}
            onClick={async ({ suggestion }) =>
              setSearch({ ...search, location: suggestion })
            }
            onChange={async (value) => {
              setSearch({ ...search, location: value });
            }}
            updater={async (value) => {
              return await request(
                "/city_name?search=" + value,
                false,
                "GET"
              ).then(({ data }) => {
                return data.data.map(
                  (event) => event.name + " - " + event.state.initials
                );
              });
            }}
          />
        </div>
      </div>

      {/* data e hora */}
      <div className={style.searchWithFiltersBar__section}>
        <div className={style.searchWithFiltersBar__section__title}>
          Data e hora
        </div>
        <div className={style.searchWithFiltersBar__section__content}>
          <button
            type="button"
            ref={sectionThreeButtonRef}
            className={style.searchWithFiltersBar__section__content__button}
            onClick={() => {
              if (sectionFourOpen) return;

              setSectionThreeOpen((old) => !old);
            }}
          >
            {search?.date && search?.time
              ? new Date(search.date).toString()
              : "Quando vai ser?"}
          </button>
          <BalloonWrapper
            refValue={sectionThreeWrapperRef}
            open={sectionThreeOpen}
            onClick={(event) => event.stopPropagation()}
          >
            <div className={style.searchWithFiltersBar__section__content__time}>
              <ToggleButtonComponent
                value={toggleButtonValue}
                triggerToggle={() =>
                  setToggleButtonValue((old) =>
                    old === "Calendário" ? "Hora" : "Calendário"
                  )
                }
                checkValue={"Calendário"}
                uncheckValue={"Hora"}
                style={{
                  marginBottom: "1.5rem",
                }}
              />
              {toggleButtonValue === "Calendário" ? (
                <CalendarComponent
                  showDoubleView={false}
                  onClickDay={(date) => {
                    setSearch({ ...search, date: new Date(date) });
                    setToggleButtonValue("Hora");
                  }}
                  tileDisabled={({ date }) => date < new Date()}
                  tileClassName={({ date }) => {
                    if (!search?.date) return "calendar-tile-common";

                    if (date.valueOf() === search?.date.valueOf())
                      return [
                        "calendar-tile-common",
                        "calendar-tile-highlight",
                      ];

                    return "calendar-tile-common";
                  }}
                  value={search?.date || new Date()}
                />
              ) : (
                <input
                  type="time"
                  className={
                    style.searchWithFiltersBar__section__content__time__input
                  }
                  value={search?.time || ""}
                  onChange={(event) => {
                    setSearch({ ...search, time: event.target.value });
                    setSectionThreeOpen(false);
                  }}
                />
              )}
            </div>
          </BalloonWrapper>
        </div>
      </div>

      {/* convidados */}
      <div className={style.searchWithFiltersBar__section}>
        <div className={style.searchWithFiltersBar__section__title}>
          Convidados
        </div>
        <div className={style.searchWithFiltersBar__section__content}>
          <button
            type="button"
            ref={sectionFourButtonRef}
            className={style.searchWithFiltersBar__section__content__button}
            onClick={() => {
              if (sectionThreeOpen) return;

              setSectionFourOpen((old) => !old);
            }}
          >
            {search?.guests
              ? search.guests.adults +
                search.guests.children +
                search.guests.babies +
                " convidados"
              : "Quantos convidados?"}
          </button>
          <BalloonWrapper
            refValue={sectionFourWrapperRef}
            open={sectionFourOpen}
            onClick={(event) => event.stopPropagation()}
          >
            <div
              className={style.searchWithFiltersBar__section__content__guests}
            >
              <div
                className={
                  style.searchWithFiltersBar__section__content__guests__description
                }
              >
                <strong>Adultos</strong>
                <p>18 anos ou mais</p>
              </div>
              <div
                className={
                  style.searchWithFiltersBar__section__content__guests__actions
                }
              >
                <button
                  type="button"
                  onClick={() =>
                    setSearch((old) => ({
                      ...old,
                      guests: {
                        ...old?.guests,
                        adults:
                          old?.guests?.adults - 1 < 0
                            ? 0
                            : old?.guests?.adults - 1,
                      },
                    }))
                  }
                >
                  -
                </button>
                <strong>{search?.guests?.adults}</strong>
                <button
                  type="button"
                  onClick={() =>
                    setSearch((old) => ({
                      ...old,
                      guests: {
                        ...old?.guests,
                        adults: old?.guests?.adults + 1,
                      },
                    }))
                  }
                >
                  +
                </button>
              </div>
            </div>
            <div
              className={style.searchWithFiltersBar__section__content__guests}
            >
              <div
                className={
                  style.searchWithFiltersBar__section__content__guests__description
                }
              >
                <strong>Crianças</strong>
                <p>Idade 2 - 17 anos</p>
              </div>
              <div
                className={
                  style.searchWithFiltersBar__section__content__guests__actions
                }
              >
                <button
                  type="button"
                  onClick={() =>
                    setSearch((old) => ({
                      ...old,
                      guests: {
                        ...old?.guests,
                        children:
                          old?.guests?.children - 1 < 0
                            ? 0
                            : old?.guests?.children - 1,
                      },
                    }))
                  }
                >
                  -
                </button>
                <strong>{search?.guests?.children}</strong>
                <button
                  type="button"
                  onClick={() =>
                    setSearch((old) => ({
                      ...old,
                      guests: {
                        ...old?.guests,
                        children: old?.guests?.children + 1,
                      },
                    }))
                  }
                >
                  +
                </button>
              </div>
            </div>
            <div
              className={style.searchWithFiltersBar__section__content__guests}
            >
              <div
                className={
                  style.searchWithFiltersBar__section__content__guests__description
                }
              >
                <strong>Bebês</strong>
                <p>Idade 0 - 2 anos</p>
              </div>
              <div
                className={
                  style.searchWithFiltersBar__section__content__guests__actions
                }
              >
                <button
                  type="button"
                  onClick={() =>
                    setSearch((old) => ({
                      ...old,
                      guests: {
                        ...old?.guests,
                        babies:
                          old?.guests?.babies - 1 < 0
                            ? 0
                            : old?.guests?.babies - 1,
                      },
                    }))
                  }
                >
                  -
                </button>
                <strong>{search?.guests?.babies}</strong>
                <button
                  type="button"
                  onClick={() =>
                    setSearch((old) => ({
                      ...old,
                      guests: {
                        ...old?.guests,
                        babies: old?.guests?.babies + 1,
                      },
                    }))
                  }
                >
                  +
                </button>
              </div>
            </div>
          </BalloonWrapper>
        </div>
      </div>

      {/* botão de buscar */}
      <div className={style.searchWithFiltersBar__button}>
        <button
          type="button"
          onClick={() => {
            history.push("/search");
          }}
        >
          <FaSearch />
          {isMobile && "Buscar"}
        </button>
      </div>
    </div>
  );
}
