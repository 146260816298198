import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useHttp } from "../../hooks/useHttp";
import { useToast } from "../../hooks/useToast";

import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Button } from "../Button";
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri";
import ReactInputMask from "react-input-mask";

import style from "./style.module.scss";

function hasAllCases(value) {
  const upper = /[A-Z]/.test(value),
    lower = /[a-z]/.test(value);
  return upper && lower;
}

function passwordIsValid(password) {
  return password?.length >= 6 && hasAllCases(password);
}

function emailCheck(email) {
  if (!email) return false;
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
}

function checkCode(code) {
  if (!code) return false;
  code = code.replace(/[^\d]+/g, "");
  if (code === "") return false;
  if (code.length !== 11 || /^(.)\1+$/.test(code)) return false;

  // verificar se cpf e valido
  let sum = 0;
  let rest;
  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(code.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(code.substring(9, 10))) return false;
  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(code.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(code.substring(10, 11))) return false;
  return true;
}

function checkPhone(phone) {
  if (!phone) return false;
  phone = phone.replace(/[^\d]+/g, "");
  if (phone === "") return false;
  return /^\d{11}$/.test(phone);
}

function checkName(name) {
  if (!name) return false;
  // const re = /^[a-zA-Z]+\s[a-zA-Z]+$/;
  const re = /[A-Z][a-z]* [A-Z][a-z]*/;
  return re.test(name);
}

const lockBtn = (value) => {
  // console.log(value);
  // console.log(
  //   checkName(value.name) &&
  //     checkPhone(value.phone) &&
  //     checkCode(value.code) &&
  //     emailCheck(value.email) &&
  //     passwordIsValid(value.password) &&
  //     value.password === value.confirmPassword &&
  //     value.birthdate?.length > 1 &&
  //     value.city !== "default" &&
  //     value.state !== "default"
  // );

  // console.log(
  //   checkName(value.name),
  //   checkPhone(value.phone),
  //   checkCode(value.code),
  //   emailCheck(value.email),
  //   passwordIsValid(value.password),
  //   value.password === value.confirmPassword,
  //   value.birthdate?.length > 1,
  //   value.city !== "default",
  //   value.state !== "default"
  // );

  if (!value) return true;
  else if (
    checkName(value.name) &&
    checkPhone(value.phone) &&
    checkCode(value.code) &&
    emailCheck(value.email) &&
    passwordIsValid(value.password) &&
    value.password === value.confirmPassword &&
    value.birthdate?.length > 1 &&
    !isValidBirthdate(value.birthdate) === false &&
    value.city !== "default" &&
    value.state !== "default"
  )
    return false;

  return true;
};

const isValidBirthdate = (birthdate) => {
  if (!birthdate) return true;

  const [day, month, year] = birthdate.split("/").map(Number);
  const today = new Date();
  const minYear = today.getFullYear() - 100;

  // Validações gerais:
  if (
    isNaN(day) ||
    isNaN(month) ||
    isNaN(year) ||
    month < 1 ||
    month > 12 ||
    year < minYear ||
    year > today.getFullYear() ||
    day < 1 ||
    day > 31
  ) {
    return false;
  }

  // Verificar dias válidos para o mês:
  const daysInMonth = new Date(year, month, 0).getDate();
  if (day > daysInMonth) {
    return false;
  }

  // Validar se a data não é futura:
  const birthdateObj = new Date(year, month - 1, day);
  if (birthdateObj > today) {
    return false;
  }

  return true;
};
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isValidPhone = (phone) => {
  const phoneRegex = /^\(\d{2}\) \d{4,5}-\d{4}$/;
  return phoneRegex.test(phone);
};

function RegisterForm() {
  const { request } = useHttp();
  const { notify } = useToast();
  const history = useHistory();

  const [user, setUser] = useState({
    city: "default",
    state: "default",
  });

  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    phone: false,
    cpf: false,
    password: false,
    birthdate: false,
    password: false,
  });

  const [seePassword, setSeePassword] = useState(false);
  const [seeConfirmPassword, setSeeConfirmPassword] = useState(false);

  useEffect(() => {
    const options = document.querySelectorAll("option");
    options.forEach((option) => {
      if (option.classList.contains("selected")) {
        if (option.innerText.includes("*")) return;
        option.innerHTML = option.innerHTML + " *";
      } else {
        if (option.innerText === "Estado *" || option.innerText === "Cidade *")
          return;
        option.innerHTML = option.innerHTML.replace(" *", "");
      }
    });
    console.log("verUser", user);
  }, [user]);

  useEffect(() => {
    (async () => {
      const { data } = await request("/state_name", false, "GET");

      setStatesList(data.data);
    })();
  }, []);

  async function handleGetCities(id) {
    const { data } = await request(`/city_name?id=${id}`, false, "GET");
    setCitiesList(data.data);
  }

  async function handleCreateUser(event) {
    event.preventDefault();

    try {
      const body = {
        ...user,
        phone: user.phone.replace(/[^0-9 ]/g, ""),
        code: user.code.replace(/[^0-9 ]/g, ""),
        birthdate: new Date(
          user.birthdate.split("/").reverse().join("/")
        ).getTime(),
      };

      const { data } = await request("/people", false, "POST", body);

      if (data.status) {
        localStorage.setItem("register/email", user.email);

        history.push("/signup/email-validation");
      } else {
        notify(
          "Erro ao cadastrar usuário",
          `Erro ao cadastrar o usuário ${user.name}, CPF e e-mail não podem estar associados à outra conta!`,
          "error"
        );
      }
    } catch (err) {
      notify("Erro ao cadastrar usuário", "Erro desconhecido", "error");
    }
  }

  return (
    <div>
      <form onSubmit={handleCreateUser} className={style.container}>
        <h1>cadastre-se</h1>
        {/*nome completo*/}
        <div className={style.input}>
          <TextField
            className={style.textField}
            variant="standard"
            required
            label="Nome Completo"
            value={user?.name || ""}
            onChange={(e) =>
              setUser((old) => ({ ...old, name: e.target.value }))
            }
          />
        </div>
        {/*pais*/}
        <div className={style.input}>
          <div>
            <select>
              <option value="default" selected style={{ color: "#ededed" }}>
                Brasil
              </option>
            </select>
          </div>
        </div>
        {/*estado*/}
        <div className={style.input}>
          <div>
            <select
              value={user?.state}
              onChange={async (e) => {
                setUser((old) => ({
                  ...old,
                  state: e.target.value,
                  city: "default",
                }));
                if (e.target.value !== "" && e.target.value !== "default") {
                  await handleGetCities(e.target.value);
                }
              }}
            >
              <option value="default" selected style={{ color: "#ededed" }}>
                Estado *
              </option>
              {statesList.map((state) => (
                <option
                  value={state._id}
                  className={state._id === user?.state ? "selected" : ""}
                >
                  {state.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/*textalert*/}
        <span className={style.instruction}>
          Por favor, primeiro selecione um estado, depois uma cidade.
        </span>
        {/*cidade*/}
        <div className={style.input}>
          <div>
            <select
              disabled={citiesList.length <= 0}
              value={user?.city}
              onChange={(e) => {
                setUser((old) => ({ ...old, city: e.target.value }));
              }}
              className={style.selectCities}
            >
              <option value="default" selected style={{ color: "#ededed" }}>
                Cidade *
              </option>
              {citiesList.map((city) => (
                <option
                  value={city._id}
                  className={city._id === user?.city ? "selected" : ""}
                >
                  {city.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/*telefone*/}
        <div className={style.input}>
          <ReactInputMask
            value={user?.phone || ""}
            onChange={(e) =>
              setUser((old) => ({ ...old, phone: e.target.value }))
            }
            onBlur={() =>
              setTouchedFields((prev) => ({ ...prev, phone: true }))
            }
            mask="(99) 99999-9999"
          >
            {() => (
              <TextField
                className={style.textField}
                variant="standard"
                label="Telefone"
                required
                error={touchedFields.phone && !isValidPhone(user?.phone)}
                helperText={
                  touchedFields.phone && !isValidPhone(user?.phone)
                    ? "Telefone inválido. Insira um número com DDD e válido."
                    : ""
                }
              />
            )}
          </ReactInputMask>
        </div>
        {/* nascimento */}
        <div className={style.input}>
          <ReactInputMask
            value={user?.birthdate || ""}
            onChange={(e) =>
              setUser((old) => ({ ...old, birthdate: e.target.value }))
            }
            mask="99/99/9999"
          >
            {() => (
              <TextField
                className={style.textField}
                variant="standard"
                label="Data de nascimento"
                required
                error={!isValidBirthdate(user?.birthdate)}
                helperText={
                  !isValidBirthdate(user?.birthdate)
                    ? "Data inválida. Insira uma data entre 1924 e hoje."
                    : ""
                }
              />
            )}
          </ReactInputMask>
        </div>
        {/*cpf*/}
        <div className={style.input}>
          <ReactInputMask
            value={user?.code || ""}
            onChange={(e) =>
              setUser((old) => ({ ...old, code: e.target.value }))
            }
            onBlur={() => setTouchedFields((prev) => ({ ...prev, cpf: true }))}
            mask="999.999.999-99"
          >
            {() => (
              <TextField
                className={style.textField}
                variant="standard"
                label="CPF"
                required
                error={touchedFields.cpf && !checkCode(user?.code)}
                helperText={
                  touchedFields.cpf && !checkCode(user?.code)
                    ? "CPF inválido. Insira um CPF válido."
                    : ""
                }
              />
            )}
          </ReactInputMask>
        </div>
        {/*email*/}
        <div className={style.input}>
          <TextField
            className={style.textField}
            variant="standard"
            label="E-mail"
            required
            value={user?.email || ""}
            onChange={(e) =>
              setUser((old) => ({ ...old, email: e.target.value }))
            }
            onBlur={() =>
              setTouchedFields((prev) => ({ ...prev, email: true }))
            }
            error={touchedFields.email && !isValidEmail(user?.email)}
            helperText={
              touchedFields.email && !isValidEmail(user?.email)
                ? "E-mail inválido. Insira um e-mail válido."
                : ""
            }
          />
        </div>
        {/* senha */}
        <div className={style.input}>
          <TextField
            className={style.textField}
            variant="standard"
            label="Senha"
            type={seePassword ? "text" : "password"}
            error={touchedFields.password && !passwordIsValid(user?.password)}
            helperText="A Senha deve ter no mínimo 6 caracteres e possuir letras maiúsculas e minúsculas."
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setSeePassword((old) => !old)}
                  >
                    {seePassword ? <RiEyeFill /> : <RiEyeCloseFill />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={user?.password || ""}
            required
            onChange={(e) =>
              setUser((old) => ({ ...old, password: e.target.value }))
            }
            onBlur={() =>
              setTouchedFields((prev) => ({ ...prev, password: true }))
            }
          />
        </div>
        {/* confirme sua senha */}
        <div className={style.input}>
          <TextField
            variant="standard"
            label="Confirme sua senha"
            className={style.textField}
            type={seeConfirmPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={() => setSeeConfirmPassword((old) => !old)}
                  >
                    {seeConfirmPassword ? <RiEyeFill /> : <RiEyeCloseFill />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={user?.confirmPassword || ""}
            onChange={(e) =>
              setUser((old) => ({ ...old, confirmPassword: e.target.value }))
            }
            error={
              user?.confirmPassword !== user?.password &&
              user?.confirmPassword !== ""
            }
            helperText="As senhas devem ser iguais!"
            required
          />
        </div>
        {/*termos e privacidade*/}
        <div className={style.policyTerms}>
          <p>
            Ao clicar em Concordar e continuar, concordo com os
            <a href="/use-terms" target="_blank" rel="noopener noreferrer">
              &nbsp;Termos de Serviço
            </a>
            &nbsp;e com a&nbsp;
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              &nbsp;Política de Privacidade
            </a>
            &nbsp;da Dream Buffets.
          </p>
        </div>
        {/*concordar e continuar*/}
        <div className={style.actions}>
          <Button
            type="submit"
            text="CONCORDAR E CONTINUAR"
            disabled={lockBtn(user)}
            className={lockBtn(user) ? style.disabled : style.registerBtn}
          />
        </div>
        {/*fazer*/}
        <p className={style.moreActions}>
          Já tem uma conta?{" "}
          <span
            onClick={() => {
              history.push("/signin");
            }}
          >
            {" "}
            Entrar
          </span>
        </p>
      </form>
    </div>
  );
}

export default RegisterForm;
