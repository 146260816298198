// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_header__1Gp6L {\n  width: 100%;\n  margin-bottom: 1rem;\n  text-align: center;\n}\n.style_header__1Gp6L strong {\n  font-size: 1.3rem;\n  font-weight: 900;\n}\n\n.style_content__32l77 {\n  width: 100%;\n  height: 80%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n}\n.style_content__32l77 button {\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  font-size: 1.1rem;\n  font-weight: 400;\n  letter-spacing: 1px;\n  display: flex;\n  align-items: center;\n  transition: all 0.2s ease-in-out;\n}\n.style_content__32l77 button:hover {\n  color: #00c2ff;\n  font-weight: 500;\n}\n.style_content__32l77 button svg {\n  margin-right: 0.5rem;\n  margin-top: -0.3rem;\n}", "",{"version":3,"sources":["webpack://src/components/ShareModal/style.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,iBAAA;EACA,gBAAA;AACJ;;AAGA;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;AAAF;AAEE;EACE,6BAAA;EACA,YAAA;EACA,eAAA;EAEA,iBAAA;EACA,gBAAA;EACA,mBAAA;EAEA,aAAA;EACA,mBAAA;EAEA,gCAAA;AAHJ;AAKI;EACE,cAAA;EACA,gBAAA;AAHN;AAMI;EACE,oBAAA;EACA,mBAAA;AAJN","sourcesContent":[".header {\n  width: 100%;\n  margin-bottom: 1rem;\n  text-align: center;\n\n  strong {\n    font-size: 1.3rem;\n    font-weight: 900;\n  }\n}\n\n.content {\n  width: 100%;\n  height: 80%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n\n  button {\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n\n    font-size: 1.1rem;\n    font-weight: 400;\n    letter-spacing: 1px;\n\n    display: flex;\n    align-items: center;\n\n    transition: all 0.2s ease-in-out;\n\n    &:hover {\n      color: #00c2ff;\n      font-weight: 500;\n    }\n\n    svg {\n      margin-right: 0.5rem;\n      margin-top: -0.3rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "style_header__1Gp6L",
	"content": "style_content__32l77"
};
export default ___CSS_LOADER_EXPORT___;
