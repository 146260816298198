// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_searchBackground__3PsCD {\n  height: 25rem;\n  width: 100vw;\n  position: relative;\n}\n.style_searchBackground__3PsCD .style_searchBackground__image__1zzad {\n  position: absolute;\n  background-color: #000;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  z-index: -1;\n  filter: brightness(0.6);\n}\n.style_searchBackground__3PsCD .style_searchBackground__content__3RID_ {\n  position: absolute;\n  width: 100%;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  color: #fff;\n  text-align: center;\n  z-index: 1;\n}", "",{"version":3,"sources":["webpack://src/components/SearchBackground/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,sBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,4BAAA;EACA,2BAAA;EACA,WAAA;EACA,uBAAA;AACJ;AAEE;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;AAAJ","sourcesContent":[".searchBackground {\n  height: 25rem;\n  width: 100vw;\n  position: relative;\n\n  .searchBackground__image {\n    position: absolute;\n    background-color: #000;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center;\n    z-index: -1;\n    filter: brightness(0.6);\n  }\n\n  .searchBackground__content {\n    position: absolute;\n    width: 100%;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    color: #fff;\n    text-align: center;\n    z-index: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBackground": "style_searchBackground__3PsCD",
	"searchBackground__image": "style_searchBackground__image__1zzad",
	"searchBackground__content": "style_searchBackground__content__3RID_"
};
export default ___CSS_LOADER_EXPORT___;
