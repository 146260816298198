// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_eventCard__1PeEq {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  margin: 0 10px;\n}\n.style_eventCard__1PeEq .style_eventCard__image__Qd1uZ {\n  width: 20rem;\n  height: 20rem;\n  padding: 1px;\n  border-radius: 30px;\n  background-color: #fff;\n  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);\n  transition: all 0.2s ease-in-out;\n  cursor: pointer;\n}\n.style_eventCard__1PeEq .style_eventCard__image__Qd1uZ:hover {\n  transform: translateY(-0.5rem);\n  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);\n}\n.style_eventCard__1PeEq h2 {\n  font-size: 2rem;\n  font-weight: 700;\n  margin: 1rem 0;\n  color: #4e4e4e;\n}", "",{"version":3,"sources":["webpack://src/components/EventCard/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,cAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;EACA,4CAAA;EACA,gCAAA;EACA,eAAA;AACJ;AACI;EACE,8BAAA;EACA,0CAAA;AACN;AAGE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,cAAA;AADJ","sourcesContent":[".eventCard {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  margin: 0 10px;\n  \n  .eventCard__image {\n    width: 20rem;\n    height: 20rem;\n    padding: 1px;\n    border-radius: 30px;\n    background-color: #fff;\n    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);\n    transition: all 0.2s ease-in-out;\n    cursor: pointer;\n\n    &:hover {\n      transform: translateY(-0.5rem);\n      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);\n    }\n  }\n\n  h2 {\n    font-size: 2rem;\n    font-weight: 700;\n    margin: 1rem 0;\n    color: #4e4e4e;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventCard": "style_eventCard__1PeEq",
	"eventCard__image": "style_eventCard__image__Qd1uZ"
};
export default ___CSS_LOADER_EXPORT___;
