// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes style_spin__J_ov_ {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.style_container__2yzQ- {\n  border: 2px solid #f3f3f3;\n  border-top: 2x solid #3498db;\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  animation: style_spin__J_ov_ 1s ease-in-out infinite;\n}", "",{"version":3,"sources":["webpack://src/components/Spinner/style.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;EACF;EAEA;IACE,yBAAA;EAAF;AACF;AAGA;EACE,yBAAA;EACA,4BAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,oDAAA;AADF","sourcesContent":["@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.container {\n  border: 2px solid #f3f3f3;\n  border-top: 2x solid #3498db;\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  animation: spin 1s ease-in-out infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__2yzQ-",
	"spin": "style_spin__J_ov_"
};
export default ___CSS_LOADER_EXPORT___;
