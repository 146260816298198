// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__1oo5Y {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  padding: 0 20px;\n  box-sizing: border-box;\n}\n.style_container__1oo5Y > *:not(:first-child) {\n  margin-bottom: 5px;\n}\n.style_container__1oo5Y .style_title__1mpyy {\n  font-size: 3rem;\n  font-weight: 900;\n  text-align: center;\n  color: #00C2FF;\n}\n.style_container__1oo5Y .style_description__3LL0l {\n  font-size: 1.3rem;\n  max-width: 800px;\n  text-align: center;\n  color: #000;\n}\n.style_container__1oo5Y .style_description__3LL0l .style_link__1cYK3 {\n  color: #00C2FF;\n  text-decoration: none;\n  font-weight: 600;\n}\n.style_container__1oo5Y .style_description__3LL0l .style_link__1cYK3:hover {\n  text-decoration: underline;\n}", "",{"version":3,"sources":["webpack://src/pages/EmailValidationComplete/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,sBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;AAAJ;AAGE;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AADJ;AAGI;EACE,cAAA;EACA,qBAAA;EACA,gBAAA;AADN;AAGM;EACE,0BAAA;AADR","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  padding: 0 20px;\n  box-sizing: border-box;\n\n  & > *:not(:first-child) {\n    margin-bottom: 5px;\n  }\n\n  .title {\n    font-size: 3rem;\n    font-weight: 900;\n    text-align: center;\n    color: #00C2FF;\n  }\n\n  .description {\n    font-size: 1.3rem;\n    max-width: 800px;\n    text-align: center;\n    color: #000;\n\n    .link {\n      color: #00C2FF;\n      text-decoration: none;\n      font-weight: 600;\n\n      &:hover {\n        text-decoration: underline;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__1oo5Y",
	"title": "style_title__1mpyy",
	"description": "style_description__3LL0l",
	"link": "style_link__1cYK3"
};
export default ___CSS_LOADER_EXPORT___;
