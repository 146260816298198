import { ChevronLeftOutlined } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useToast } from "../../hooks/useToast";
import { useMediaQuery } from "react-responsive";
import Navbar from "../../components/Navbar";
import PaymentComponent from "../../components/PaymentComponent";
import calendar from "../../assets/images/vectors/calendar.png";
import ReactInputMask from "react-input-mask";
import api from "../../service";
import { Modal } from "../../components/Modal";
import { BsCheckCircle, BsExclamationCircle } from "react-icons/bs";
import { Button } from "../../components/Button";
import { MenuItem, Select, TextField } from "@material-ui/core";
import { BiCreditCard } from "react-icons/bi";
import { HiLocationMarker } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { GrStatusUnknown } from "react-icons/gr";

import "./style.scss";

import visa from "../../assets/images/vectors/visa.png";
import mastercard from "../../assets/images/vectors/mastercard.png";
import amex from "../../assets/images/vectors/amex.png";
import elo from "../../assets/images/vectors/elo.png";
import hipercard from "../../assets/images/vectors/hipercard.png";
import dinersclub from "../../assets/images/vectors/dinersclub.png";
import { useReservations } from "../../hooks/useReservations";
import { useHttp } from "../../hooks/useHttp";

function Payment() {
  const { request } = useHttp();
  const { notify } = useToast();

  const [buffet, setBuffet] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [creditCard, setCreditCard] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({
    owner: {},
    address: {},
  });
  const [saveCardInfo, setSaveCardInfo] = useState(false);
  const [savedCards, setSavedCards] = useState([]);
  const [successPayment, setSuccessPayment] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 930 });

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const { createReservation, newReservation } = useReservations();
  const history = useHistory();

  const cardImages = [
    {
      name: "visa",
      image: visa,
    },
    {
      name: "mastercard",
      image: mastercard,
    },
    {
      name: "amex",
      image: amex,
    },
    {
      name: "elo",
      image: elo,
    },
    {
      name: "hipercard",
      image: hipercard,
    },
    {
      name: "diners_club",
      image: dinersclub,
    },
  ];

  const reservationBuffetID = localStorage.getItem("reservation_buffet_id");

  useEffect(() => {
    (async () => {
      if (
        reservationBuffetID === undefined ||
        newReservation?.date === undefined ||
        newReservation?.hour === undefined ||
        newReservation?.invites === undefined ||
        newReservation?.package?.value === undefined ||
        newReservation?.package?.id === undefined
      )
        history.push("/");

      const data = await api.consumer.buffets.getBuffetById(
        reservationBuffetID
      );

      setBuffet(data.data.data);
    })();
  }, [newReservation]);

  useEffect(() => {
    (async () => {
      let dataState = await api.consumer.places.getState();

      setStates(dataState.data.data);

      let dataCity = await api.consumer.places.getCity("");

      setCities(dataCity.data.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await request("/people_card", true, "GET");

        if (data?.data) {
          data.data.map((card) => {
            let newCard = {
              id: card.id,
              number: card.first_six_digits + card.last_four_digits,
              brand: card.brand,
              holder_name: card.holder_name,
              address: card.billing_address ? true : false,
            };

            let savedCard = savedCards.find((item) => item.id === newCard.id);

            if (!savedCard) {
              setSavedCards((old) => [...old, newCard]);
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleOrderValue = (total, discount) => {
    return total - (discount / 100) * total;
  };

  const handleCEPSearch = async (cep) => {
    try {
      if (cep) {
        if (cep.replace(/[^0-9 ]/g, "").length >= 8) {
          let token = localStorage.getItem("@dream/token");

          let config = {
            headers: { Authorization: `Bearer ${token}` },
          };

          let data = await api.consumer.listSearch.getAddressSearch(
            cep,
            config
          );

          if (data.data.data.street) {
            setPaymentInfo((old) => {
              let temp = { ...old };

              temp.address.address = data.data.data.street;

              return temp;
            });
          }

          if (data.data.data.state) {
            const stateValue = states.find(
              (state) => state.initials === data.data.data.state
            );

            if (stateValue) {
              setPaymentInfo((old) => {
                let temp = { ...old };

                temp.address.state =
                  stateValue.name +
                  "-" +
                  stateValue.initials +
                  "-" +
                  stateValue._id;

                return temp;
              });
            }
          }

          if (data.data.data.city) {
            const cityValue = cities?.find(
              (city) => city.name === data.data.data.city
            );

            if (cityValue) {
              setPaymentInfo((old) => {
                let temp = { ...old };

                temp.address.city =
                  cityValue.name +
                  "-" +
                  cityValue.initials +
                  "-" +
                  cityValue._id;

                return temp;
              });
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  async function handlePayment(e) {
    e.preventDefault();

    if (!buffet) {
      notify(
        "Aguarde um momento!",
        "Estamos carregando os dados do buffet.",
        "warning"
      );

      return;
    }

    setIsLoading(true);

    let body = {};

    if (creditCard?.id && creditCard?.holder_name && creditCard?.address) {
      body = {
        buffet: buffet.buffet,
        buffet_space: buffet._id,
        card: {
          id: creditCard?.id,
        },
        services: [
          {
            id: newReservation?.package?.id,
            value: newReservation?.package?.value,
            name: newReservation?.package?.name,
            description: newReservation?.package?.description,
          },
        ],
        order_value: handleOrderValue(
          newReservation?.package?.value +
            newReservation?.additionalServices?.additional_services_value +
            newReservation?.additionalProviders?.additional_providers_value,
          buffet.discount
        ),
        services_value: newReservation?.package?.value,
        additional_services: newReservation?.additionalServices?.items,
        additional_services_value:
          newReservation?.additionalServices?.additional_services_value,
        additional_providers: newReservation?.additionalProviders?.items,
        additional_providers_value:
          newReservation?.additionalProviders?.additional_providers_value,
        capacity: newReservation?.invites?._id,
        event_date: newReservation.date.valueOf(),
        event_hour: newReservation.hour,
      };
    } else {
      body = {
        buffet: buffet.buffet,
        buffet_space: buffet._id,
        customer: {
          name: paymentInfo?.owner?.name,
          phone: paymentInfo?.owner?.phone.replace(/[^0-9 ]/g, ""),
          code: paymentInfo?.owner?.code.replace(/[^0-9 ]/g, ""),
          address: {
            country: paymentInfo?.address?.country,
            state:
              paymentInfo?.address?.state &&
              paymentInfo?.address?.state.includes("-")
                ? paymentInfo?.address?.state.split(`-`)[1]
                : paymentInfo?.address?.state,
            city:
              paymentInfo?.address?.city &&
              paymentInfo?.address?.city.includes("-")
                ? paymentInfo?.address?.city.split(`-`)[0]
                : paymentInfo?.address?.city,
            zip_code: paymentInfo?.address?.code.replace(/[^0-9 ]/g, ""),
            complement: paymentInfo?.address?.complement || "",
          },
        },
        card: {
          number: creditCard.card_number.split(" ").join(""),
          exp_month: parseFloat(creditCard.valid_date.split("/")[0]),
          exp_year: parseFloat(creditCard.valid_date.split("/")[1]),
          cvv: creditCard.security_code,
          save: saveCardInfo,
        },
        services: [
          {
            id: newReservation?.package?.id,
            value: newReservation?.package?.value,
            name: newReservation?.package?.name,
            description: newReservation?.package?.description,
          },
        ],
        services_value: newReservation?.package?.value,
        order_value: handleOrderValue(
          newReservation?.package?.value +
            newReservation?.additionalServices?.additional_services_value +
            newReservation?.additionalProviders?.additional_providers_value,
          buffet.discount
        ),
        additional_services: newReservation?.additionalServices?.items,
        additional_services_value:
          newReservation?.additionalServices?.additional_services_value,
        additional_providers: newReservation?.additionalProviders?.items,
        additional_providers_value:
          newReservation?.additionalProviders?.additional_providers_value,
        capacity: newReservation?.invites?._id,
        event_date: newReservation.date.valueOf(),
        event_hour: newReservation.hour,
      };
    }

    try {
      let { status } = await createReservation(body);

      setSuccessPayment(status);
    } finally {
      setIsLoading(false);

      setShowModal(true);
    }
  }

  return (
    <div className="container-home">
      <Navbar />
      <div
        style={{
          display: "flex",
          flexDirection: isMobile && "column",
          padding: 30,
          margin: 0,
        }}
      >
        <form
          onSubmit={handlePayment}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            onClick={() => history.goBack()}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <ChevronLeftOutlined size="28" />
            <p
              style={{
                marginLeft: 20,
                fontFamily: "Catamaran",
                color: "#333333",
                fontWeight: "bold",
                fontSize: 28,
              }}
            >
              Pedir para reservar
            </p>
          </div>
          {isMobile && (
            <div>
              <PaymentComponent
                name={buffet?.name}
                score={buffet?.opinion_score}
                picture={buffet?.thumbnail_picture}
                totalOpinions={buffet?.opinions.length}
                packageValue={newReservation?.package?.value}
                discount={buffet?.discount}
                total={
                  handleOrderValue(
                    newReservation?.package?.value +
                      newReservation?.additionalServices
                        ?.additional_services_value +
                      newReservation?.additionalProviders
                        ?.additional_providers_value,
                    buffet?.discount
                  ) || null
                }
              />
              <hr className="separator" />
            </div>
          )}

          <div style={{ marginTop: 60 }}>
            <span style={{ color: "#33333", fontSize: 22 }}>Seu evento</span>
          </div>
          <div
            style={{
              marginTop: 10,
              width: isMobile ? "100%" : "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#33333", fontSize: 18, fontWeight: "bold" }}>
              Data e hora
            </span>
            <span
              onClick={() => history.push(`/buffet/${reservationBuffetID}`)}
              style={{
                color: "#33333",
                fontSize: 18,
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Editar
            </span>
          </div>
          <div
            style={{
              width: isMobile ? "100%" : "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#767676", fontSize: 18 }}>
              {newReservation?.date &&
                `${newReservation?.date.toLocaleDateString("pt-BR", {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}, no período da ${
                  newReservation?.hour === "afternoon"
                    ? "tarde"
                    : newReservation?.hour === "morning"
                    ? "manhã"
                    : "noite"
                }`}
            </span>
          </div>
          <div
            style={{
              marginTop: 10,
              width: isMobile ? "100%" : "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#33333", fontSize: 18, fontWeight: "bold" }}>
              Convidados
            </span>
            <span
              onClick={() => history.push(`/buffet/${reservationBuffetID}`)}
              style={{
                color: "#33333",
                fontSize: 18,
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Editar
            </span>
          </div>
          <div
            style={{
              width: isMobile ? "100%" : "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#767676", fontSize: 18 }}>
              {newReservation?.invites && newReservation.invites.name}{" "}
              convidado(s)
            </span>
          </div>
          <hr className="separator" />
          <div
            style={{
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              width: isMobile ? "100%" : "80%",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                color: "#33333",
                fontSize: isMobile ? 16 : 24,
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              Pagar com
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img style={{ marginLeft: isMobile ? 10 : 20 }} src={visa} />
              <img
                style={{ marginLeft: isMobile ? 10 : 20 }}
                src={mastercard}
              />
              <img style={{ marginLeft: isMobile ? 10 : 20 }} src={amex} />
              <img style={{ marginLeft: isMobile ? 10 : 20 }} src={elo} />
              <img style={{ marginLeft: isMobile ? 10 : 20 }} src={hipercard} />
              <img
                style={{ marginLeft: isMobile ? 10 : 20 }}
                src={dinersclub}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              width: isMobile ? "100%" : "80%",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #B8B8B8",
            }}
          >
            <BiCreditCard size="22" style={{ marginRight: 10 }} />
            <Select
              disableUnderline
              variant="standard"
              style={{ width: "100%" }}
              value={creditCard}
              onChange={(e) => setCreditCard(e.target.value)}
              className="remove__mui__style"
            >
              <MenuItem disabled value="default">
                Cartão de Crédito
              </MenuItem>
              {savedCards.map((creditCard) => (
                <MenuItem
                  key={creditCard.id}
                  value={creditCard}
                  className="credit-card-item"
                >
                  <div
                    className={`credit-card-item-body ${
                      creditCard?.brand.toLowerCase() === "diners_club" ||
                      creditCard?.brand.toLowerCase() === "amex" ||
                      creditCard?.brand.toLowerCase() === "visa"
                        ? "lightBg"
                        : ""
                    }`}
                  >
                    {!creditCard.brand ? (
                      <GrStatusUnknown size="0.8rem" />
                    ) : (
                      <img
                        src={
                          cardImages.find(
                            (card) =>
                              card.name === creditCard.brand.toLowerCase()
                          )?.image
                        }
                        alt={creditCard.brand}
                      />
                    )}
                  </div>
                  <span style={{ marginLeft: 12 }}>{creditCard?.brand}</span>
                  <span style={{ marginLeft: 12 }}>
                    {creditCard?.number.substring(creditCard.number.length - 4)}
                  </span>
                </MenuItem>
              ))}
            </Select>
          </div>

          {!!creditCard?.id ? (
            ""
          ) : (
            <>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  width: isMobile ? "100%" : "80%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ReactInputMask
                  onChange={(e) =>
                    setCreditCard((old) => ({
                      ...old,
                      card_number: e.target.value,
                    }))
                  }
                  value={creditCard?.card_number}
                  mask="9999 9999 9999 9999"
                >
                  {() => (
                    <TextField
                      variant="standard"
                      label="Número do cartão"
                      style={{ width: "100%" }}
                    />
                  )}
                </ReactInputMask>
              </div>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  width: isMobile ? "100%" : "80%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ReactInputMask
                  onChange={(e) =>
                    setCreditCard((old) => ({
                      ...old,
                      valid_date: e.target.value,
                    }))
                  }
                  value={creditCard?.valid_date}
                  mask="99/99"
                >
                  {() => (
                    <TextField
                      variant="standard"
                      label="Validade"
                      style={{ width: "45%" }}
                    />
                  )}
                </ReactInputMask>
                <ReactInputMask
                  onChange={(e) =>
                    setCreditCard((old) => ({
                      ...old,
                      security_code: e.target.value,
                    }))
                  }
                  value={creditCard?.security_code}
                  mask="999"
                >
                  {() => (
                    <TextField
                      variant="standard"
                      label="CVV"
                      style={{ width: "45%" }}
                    />
                  )}
                </ReactInputMask>
              </div>
            </>
          )}

          {!!creditCard?.holder_name ? (
            ""
          ) : (
            <>
              <span
                style={{ color: "#33333", fontSize: 22, margin: "25px 0 0 0 " }}
              >
                Informações do titular do cartão
              </span>
              <div
                style={{
                  display: "flex",
                  width: isMobile ? "100%" : "80%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="standard"
                  label="Nome impresso no cartão"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setPaymentInfo((old) => {
                      let temp = { ...old };

                      temp.owner.name = e.target.value;

                      return temp;
                    })
                  }
                  value={paymentInfo?.owner?.name}
                />
              </div>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  width: isMobile ? "100%" : "80%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ReactInputMask
                  onChange={(e) =>
                    setPaymentInfo((old) => {
                      let temp = { ...old };

                      temp.owner.code = e.target.value;

                      return temp;
                    })
                  }
                  value={paymentInfo?.owner?.code}
                  mask="999.999.999-99"
                >
                  {() => (
                    <TextField
                      variant="standard"
                      label="CPF"
                      style={{ width: "45%" }}
                    />
                  )}
                </ReactInputMask>
                <ReactInputMask
                  onChange={(e) =>
                    setPaymentInfo((old) => {
                      let temp = { ...old };

                      temp.owner.birth_date = e.target.value;

                      return temp;
                    })
                  }
                  value={paymentInfo?.owner?.birth_date}
                  mask="99/99/9999"
                >
                  {() => (
                    <TextField
                      variant="standard"
                      label="Data de nascimento"
                      style={{ width: "45%" }}
                    />
                  )}
                </ReactInputMask>
              </div>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  width: isMobile ? "100%" : "80%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ReactInputMask
                  onChange={(e) => {
                    setPaymentInfo((old) => {
                      let temp = { ...old };

                      temp.owner.phone = e.target.value;

                      return temp;
                    });
                  }}
                  value={paymentInfo?.owner?.phone}
                  mask="(99) 99999-9999"
                >
                  {() => (
                    <TextField
                      variant="standard"
                      label="Telefone celular"
                      style={{ width: "100%" }}
                    />
                  )}
                </ReactInputMask>
              </div>
            </>
          )}

          {!!creditCard?.address ? (
            ""
          ) : (
            <>
              <span
                style={{ color: "#33333", fontSize: 22, margin: "25px 0 0 0 " }}
              >
                Endereço de cobrança
              </span>
              <div
                style={{
                  display: "flex",
                  width: isMobile ? "100%" : "80%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ReactInputMask
                  onChange={(e) => {
                    setPaymentInfo((old) => {
                      let temp = { ...old };

                      temp.address.code = e.target.value;

                      return temp;
                    });
                  }}
                  onBlur={() => handleCEPSearch(paymentInfo?.address?.code)}
                  value={paymentInfo?.address?.code}
                  mask="99999-999"
                >
                  {() => (
                    <TextField
                      variant="standard"
                      label="CEP"
                      style={{ width: "100%" }}
                    />
                  )}
                </ReactInputMask>
              </div>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  width: isMobile ? "100%" : "80%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="standard"
                  label={paymentInfo?.address?.address ? "" : "Endereço"}
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setPaymentInfo((old) => {
                      let temp = { ...old };

                      temp.address.address = e.target.value;

                      return temp;
                    })
                  }
                  value={paymentInfo?.address?.address}
                />
              </div>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  width: isMobile ? "100%" : "80%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="standard"
                  label="Número"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setPaymentInfo((old) => {
                      let temp = { ...old };

                      temp.address.number = e.target.value;

                      return temp;
                    })
                  }
                  value={paymentInfo?.address?.number}
                />
              </div>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  width: isMobile ? "100%" : "80%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="standard"
                  label="Complemento (opcional)"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setPaymentInfo((old) => {
                      let temp = { ...old };

                      temp.address.complement = e.target.value;

                      return temp;
                    })
                  }
                  value={paymentInfo?.address?.complement}
                />
              </div>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  width: isMobile ? "100%" : "80%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Select
                  style={{ width: "100%", marginTop: 13 }}
                  placeholder="Estado"
                  label="Estado"
                  variant="standard"
                  value={paymentInfo?.address?.state || 0}
                  onChange={async (e) => {
                    setPaymentInfo((old) => {
                      let temp = { ...old };

                      temp.address.state = e.target.value;

                      return temp;
                    });

                    let { data } = await api.consumer.places.getCity(
                      e.target.value.split(`-`)[2]
                    );

                    setCities(data.data);
                  }}
                >
                  <MenuItem disabled selected value={0}>
                    Estado
                  </MenuItem>
                  {states.map((state) => {
                    return (
                      <MenuItem
                        value={
                          state.name + "-" + state.initials + "-" + state._id
                        }
                      >
                        {state.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  width: isMobile ? "100%" : "80%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Select
                  placeholder="Cidade"
                  label="Cidade"
                  variant="standard"
                  style={{
                    width: "100%",
                    marginTop: 13,
                  }}
                  disabled={cities.length == 0}
                  value={paymentInfo?.address?.city || 0}
                  onChange={async (e) => {
                    setPaymentInfo((old) => {
                      let temp = { ...old };

                      temp.address.city = e.target.value;

                      return temp;
                    });
                  }}
                >
                  <MenuItem disabled selected value={0}>
                    Cidade
                  </MenuItem>
                  {cities.map((city) => (
                    <MenuItem
                      value={city.name + "-" + city.initials + "-" + city._id}
                    >
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  width: isMobile ? "100%" : "80%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Select
                  style={{ width: "100%", marginTop: 13 }}
                  placeholder="País"
                  label="País"
                  value={paymentInfo?.address?.country || 0}
                  onChange={async (e) => {
                    setPaymentInfo((old) => {
                      let temp = { ...old };

                      temp.address.country = e.target.value;

                      return temp;
                    });
                  }}
                  variant="standard"
                >
                  <MenuItem disabled selected value={0}>
                    País/Região
                  </MenuItem>
                  <MenuItem value="Brasil">Brasil</MenuItem>
                </Select>
              </div>
            </>
          )}

          {!creditCard?.id && (
            <>
              <div className="save-card-info">
                <input
                  type="checkbox"
                  checked={saveCardInfo}
                  onChange={(e) => {
                    setSaveCardInfo(e.target.checked);
                  }}
                  name="save-card-info-btn"
                  id="save-card-info-btn"
                />
                <label htmlFor="save-card-info-btn">
                  Salvar informações do cartão para utilizar em compras futuras
                </label>
              </div>
              <hr className="separator" />
            </>
          )}
          <span style={{ color: "#33333", fontSize: 24, fontWeight: "bold" }}>
            Política de cancelamento
          </span>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              width: isMobile ? "100%" : "80%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={calendar} style={{ marginRight: 20 }} />
            </div>
            <div>
              <p style={{ color: "#333333", fontSize: 18, fontWeight: "bold" }}>
                Sua reserva só será confirmada depois que o buffet aceitar seu
                pedido (dentro de 24 horas).
              </p>
              <p style={{ color: "#686868", fontWeight: "bold" }}>
                A cobrança só é feita depois.
              </p>
            </div>
          </div>
          <p style={{ color: "#686868", width: "81%" }}>
            Ao clicar no botão abaixo, eu concordo com a{" "}
            <span
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Regras do Buffet, Divulgações de segurança, O distanciamento
              social e outras orientações da Dream Buffets relacionadas à
              COVID-19, Política de Cancelamento e a Política de Reembolso do
              cliente.
            </span>{" "}
            Eu também concordo em pagar o valor total exibido, que inclui as
            taxas de serviço. Termos de Pagamento entre você e Dream Buffets.
          </p>

          <Button
            text="PEDIR PARA RESERVAR"
            type="submit"
            loading={isLoading}
            customStyles={{
              backgroundColor: "#00C2FF",
              fontWeight: "900",
              width: "20em",
              color: "#fff",
              fontSize: isMobile ? 14 : 18,
              borderRadius: 22,
              marginTop: 40,
              marginBottom: 40,
              marginLeft: isMobile && "auto",
              marginRight: isMobile && "auto",
            }}
          />
        </form>
        {!isMobile && (
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PaymentComponent
              name={buffet?.name}
              score={buffet?.opinion_score}
              picture={buffet?.thumbnail_picture}
              totalOpinions={buffet?.opinions.length}
              packageValue={newReservation?.package?.value}
              discount={buffet?.discount}
              total={
                handleOrderValue(
                  newReservation?.package?.value +
                    newReservation?.additionalServices
                      ?.additional_services_value +
                    newReservation?.additionalProviders
                      ?.additional_providers_value,
                  buffet?.discount
                ) || null
              }
            />
          </div>
        )}

        {showModal && successPayment ? (
          <Modal
            darkBackground
            modalPosition="centered"
            modalSize="big"
            closeIconPosition="right"
            handleClose={() => {
              setShowModal(false);

              history.push("/my-requests");
            }}
          >
            <div className="modal-header-container">
              <strong>Pedido recebido!</strong>
            </div>
            <div className="modal-content-container">
              <BsCheckCircle color="#00c2ff" size="7em" />
              <p
                style={{
                  textAlign: "center",
                }}
              >
                Seu evento foi reservado para o dia{" "}
                <span>
                  {new Date(newReservation?.date).toLocaleString("pt-BR", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </span>{" "}
                em <span>{buffet?.name}</span>. Mais detalhes foram enviados ao
                e-mail cadastrado.
              </p>
              <div className="addressInModalConfirmation">
                <HiLocationMarker />
                <p>
                  <strong>{buffet?.name}</strong>
                  <br />
                  {buffet?.address?.street}, {buffet?.address?.city} -{" "}
                  {buffet?.address?.state}
                </p>
              </div>
            </div>
            <div className="modal-actions-container">
              <Button
                text="MEUS PEDIDOS"
                type="button"
                onClickFunction={() => history.push("/my-requests")}
                customStyles={{
                  backgroundColor: "#00C2FF",
                  fontWeight: "900",
                  width: "14em",
                  color: "#fff",
                  fontSize: isMobile ? 14 : 18,
                  borderRadius: 22,
                  marginTop: 40,
                  marginBottom: 40,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </div>
          </Modal>
        ) : showModal && successPayment === false ? (
          <Modal
            darkBackground
            modalPosition="centered"
            modalSize="big"
            closeIconPosition="right"
            handleClose={() => {
              setShowModal(false);
            }}
          >
            <div className="modal-header-container">
              <strong>Pagamento falhou!</strong>
            </div>
            <div className="modal-content-container">
              <BsExclamationCircle color="#FD4F29" size="7em" />
              <p
                style={{
                  textAlign: "center",
                }}
              >
                Nós identificamos um problema ao processar o seu pagamento. Por
                favor, tente novamente.
              </p>
            </div>
            <div className="modal-actions-container">
              <Button
                text="TENTAR NOVAMENTE"
                type="button"
                onClickFunction={() => {
                  setShowModal(false);
                }}
                customStyles={{
                  backgroundColor: "#00C2FF",
                  fontWeight: "900",
                  width: "14em",
                  color: "#fff",
                  fontSize: isMobile ? 14 : 18,
                  borderRadius: 22,
                  marginTop: 40,
                  marginBottom: 40,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </div>
          </Modal>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Payment;
