import groupFixing from '../../assets/images/groupFixing.svg'
import style from './style.module.scss'
import logo from '../../assets/images/logo.png'
import { Button } from '../../components/Button';
import { useMediaQuery } from 'react-responsive';

function Maintenance () {
  const isMobile = useMediaQuery({ maxWidth: 990 });

  function refreshPage () {
    window.location.reload(false);
  }

  return (
    <div className={style.container}>
      {isMobile && <img src={logo} alt='Dream Buffets' className={style.logo} />}
      <div className={style.imgContainer}>
        <img src={groupFixing} alt="Grupo Fazendo Manutenção" />
      </div>
      <div className={style.pageContent}>
        {!isMobile && <img src={logo} alt='Dream Buffets' className={style.logo} />}
        <h1>Em manutenção!</h1>
        <p>No momento nossos serviços não estão disponíveis pois estamos fazendo alguns ajustes. Tente novamente mais tarde.</p>

        <Button
          type="button"
          text="Tentar novamente"
          onClickFunction={refreshPage}
          className={style.btn}
        />
      </div>
    </div>
  )
}

export default Maintenance;
