// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_autocomplete__Z4-js input {\n  width: 100%;\n  border: none;\n  box-sizing: border-box;\n  font-size: 1.1rem;\n  outline: none;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.style_autocomplete__Z4-js .style_autocomplete__results__1yffv {\n  list-style: none;\n}\n.style_autocomplete__Z4-js .style_autocomplete__results__1yffv li {\n  padding: 0.5rem;\n  cursor: pointer;\n  font-size: 1rem;\n  color: #000;\n}\n.style_autocomplete__Z4-js .style_autocomplete__results__1yffv li:hover {\n  color: #626262;\n}\n.style_autocomplete__Z4-js .style_autocomplete__results__1yffv .style_activeSuggestion__Ke99K {\n  color: #626262;\n}", "",{"version":3,"sources":["webpack://src/components/Autocomplete/style.module.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,YAAA;EAEA,sBAAA;EACA,iBAAA;EACA,aAAA;EAEA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAFJ;AAKE;EACE,gBAAA;AAHJ;AAKI;EACE,eAAA;EACA,eAAA;EACA,eAAA;EACA,WAAA;AAHN;AAKM;EACE,cAAA;AAHR;AAOI;EACE,cAAA;AALN","sourcesContent":[".autocomplete {\n  input {\n    width: 100%;\n    border: none;\n\n    box-sizing: border-box;\n    font-size: 1.1rem;\n    outline: none;\n\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n\n  .autocomplete__results {\n    list-style: none;\n\n    li {\n      padding: 0.5rem;\n      cursor: pointer;\n      font-size: 1rem;\n      color: #000;\n\n      &:hover {\n        color: #626262;\n      }\n    }\n\n    .activeSuggestion {\n      color: #626262;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autocomplete": "style_autocomplete__Z4-js",
	"autocomplete__results": "style_autocomplete__results__1yffv",
	"activeSuggestion": "style_activeSuggestion__Ke99K"
};
export default ___CSS_LOADER_EXPORT___;
