// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_balloon__1yZWz {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  z-index: 999999;\n  background: #fff;\n  border-radius: 25px;\n  padding: 20px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n  transform: translateY(105%) translateX(-30%);\n  width: -moz-fit-content;\n  width: fit-content;\n  height: -moz-fit-content;\n  height: fit-content;\n}\n@media (max-width: 931px) {\n  .style_balloon__1yZWz {\n    transform: translateY(105%);\n  }\n}\n.style_balloon__1yZWz::before {\n  content: \"\";\n  position: absolute;\n  top: -0.5rem;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 0 0.5rem 0.5rem 0.5rem;\n  border-color: transparent transparent #fff transparent;\n}", "",{"version":3,"sources":["webpack://src/components/BalloonWrapper/style.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,uCAAA;EACA,4CAAA;EACA,uBAAA;EAAA,kBAAA;EACA,wBAAA;EAAA,mBAAA;AACF;AACE;EAbF;IAcI,2BAAA;EAEF;AACF;AAAE;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,SAAA;EACA,2BAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;EACA,oCAAA;EACA,sDAAA;AAEJ","sourcesContent":[".balloon {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  z-index: 999999;\n  background: #fff;\n  border-radius: 25px;\n  padding: 20px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n  transform: translateY(105%) translateX(-30%);\n  width: fit-content;\n  height: fit-content;\n\n  @media (max-width: 931px) {\n    transform: translateY(105%);\n  }\n  \n  &::before {\n    content: '';\n    position: absolute;\n    top: -0.5rem;\n    left: 50%;\n    transform: translateX(-50%);\n    width: 0;\n    height: 0;\n    border-style: solid;\n    border-width: 0 0.5rem 0.5rem 0.5rem;\n    border-color: transparent transparent #fff transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balloon": "style_balloon__1yZWz"
};
export default ___CSS_LOADER_EXPORT___;
