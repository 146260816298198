import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/useToast";
import { useHttp } from "../../hooks/useHttp";

import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri";

import style from "./style.module.scss";

function RecoverForm({ token }) {
  const history = useHistory();
  const { notify } = useToast();
  const { request } = useHttp();

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [seePassword, setSeePassword] = useState(false);
  const [seePasswordConfirm, setSeePasswordConfirm] = useState(false);

  async function handleSetNewPass(e) {
    e.preventDefault();

    let body = {
      password: password,
      token: token,
      type: "people",
    };

    try {
      const { data } = await request("/set_new_password", false, "POST", body);

      if (data.data.status) {
        notify(
          "Senha alterada com sucesso!",
          "Você já pode entrar com sua nova senha.",
          "success"
        );

        history.push("/signin");
      } else {
        notify(
          "Erro ao alterar senha",
          "Não foi possível alterar sua senha",
          "error"
        );
      }
    } catch (e) {
      notify("Não foi possível alterar sua senha", `${e.message}`, "error");
    }
  }

  function hasAllCases(value) {
    const upper = /[A-Z]/.test(value),
      lower = /[a-z]/.test(value);

    return upper && lower;
  }

  const isPasswordValid = password.length >= 6 && hasAllCases(password);

  return (
    <div>
      <form onSubmit={handleSetNewPass} className={style.form}>
        <h1>Criar nova senha</h1>
        <p className={style.instruction}>
          A sua nova senha deve ser diferente das senhas usadas anteriormente.
        </p>
        <p className={style.instruction}>
          A Senha deve ter no mínimo 6 caracteres e possuir letras maiúsculas e
          minúsculas.
        </p>

        <div className={style.input} style={{ marginTop: "10%" }}>
          <TextField
            variant="standard"
            label="Nova senha"
            className={style.textField}
            type={seePassword ? "text" : "password"}
            error={!isPasswordValid && password.length > 0}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => setSeePassword(!seePassword)}
                    aria-label="toggle password visibility"
                  >
                    {seePassword ? <RiEyeFill /> : <RiEyeCloseFill />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={style.input}>
          <TextField
            error={password !== passwordConfirm && passwordConfirm !== ""}
            variant="standard"
            label="Confirme sua senha"
            style={{ width: "50%", marginBottom: 20 }}
            type={seePasswordConfirm ? "text" : "password"}
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => setSeePasswordConfirm(!seePasswordConfirm)}
                    aria-label="toggle password visibility"
                  >
                    {seePasswordConfirm ? <RiEyeFill /> : <RiEyeCloseFill />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className={style.actions}>
          <Button
            type="submit"
            disabled={!isPasswordValid || password !== passwordConfirm}
            className={style.newPassBtn}
          >
            Redefinir senha
          </Button>
        </div>
        <div className={style.login}>
          <a href="/signin">Fazer login</a>
        </div>
      </form>
    </div>
  );
}

export default RecoverForm;
