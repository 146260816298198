// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_list__bbvPA {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 60px 0;\n}", "",{"version":3,"sources":["webpack://src/components/HorizontalListView/style.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,cAAA;AACF","sourcesContent":[".list {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 60px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "style_list__bbvPA"
};
export default ___CSS_LOADER_EXPORT___;
